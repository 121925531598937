/*LoginModal classes*/
.modal-content {
  border-radius: 0.9rem !important;
  border: none !important;
  padding: 30px 30px 0px 30px !important;
  .orange {
    color: #ff5b22;
    cursor: pointer;
  }
  .modal-title {
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
    width: 100%;
  }
  .modal-header {
    border-bottom: none !important;
  }
  label {
    color: #737188;
    font-size: 13px;
  }
  .access-with-account {
    color: #737188;
    padding: 20px 0px 10px 0px;
    font-size: 13px;
  }
  .login-button {
    margin: 15px 0px 15px 0px;
  }
  .create-account,
  .forgot-password {
    color: #ff5b22;
    cursor: pointer;
    font-size: 13px;
    padding-top: 20px;
  }
  .forgot-password {
    text-align: right;
    margin-bottom: -22px;
  }
  .login-button {
    cursor: pointer;
    width: 100%;
  }
  .login-google-btn-modal {
    box-shadow: 1px 1px 6px #ddd;
    border-radius: 6px;
    float: left;
  }
  .login-facebook-btn {
    float: right;
  }
}

@media (max-width: 767px) {
  .logo-center {
    max-width: 160px;
    margin-top: 8px;
  }
  .user {
    width: 20px;
    margin-top: 8px;
  }
  .navbar-toggler {
    margin-top: 3px;
    .icon-bar {
      height: 3px;
      width: 27px;
      margin-top: 5px;
    }
  }
  .buttonContainer {
    padding-left: 0 !important;
    right: 10px;
  }
  .iconContainer {
    padding-right: 0px !important;
  }
}
