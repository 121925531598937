.PaypalV2 {
  position: relative;
  width: 100%;
  #ppplus iframe {
    width: 100% !important;
    -ms-overflow-y: hidden;
    -ms-overflow-x: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  #ppplus,
  #ppplus iframe {
    height: 600px !important;
  }

  .spinner.loading {
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
  }
  .spinner.loading:before {
    content: '';
    height: 100px;
    width: 100px;
    margin: -50px auto auto -50px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-width: 15px;
    border-style: solid;
    border-color: #2180c0 #ccc #ccc;
    border-radius: 100%;
    animation: rotation 0.7s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

// @media (max-width: 600px) {
//   .PaypalV2 {
//     #ppplus,
//     #ppplus iframe {
//       height: 639px !important;
//     }
//   }
// }
