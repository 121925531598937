@media (max-width: 767px) {
  .footer {
    h5 {
      text-align: left;
    }
    ul {
      li {
        a {
          text-align: left;
        }
      }
    }
    .footer-last-row {
      .cards {
        text-align: center;
      }
      .copyright {
        text-align: center;
      }
    }
  }
}
