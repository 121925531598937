.imgbrowser {
  margin-bottom: 5px;
}
.textbrowser {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #737188;
}
 .titelbrowser{
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-align: center;

  color: #120f46;
}
.contentbrowser {
  margin-top: 11%;
}
.rowb {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imgBar {
  flex-grow: inherit;
}
.imgBar > p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  color: #737188;
  margin-left: 18%;
}
