img.login-button.login-google-btn {
  margin-top: -5px;
}

.sign-in-anonymously-button {
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
}
.login-button {
  cursor: pointer;
}

.Register {
  display: flex;
  width: 100%;
  .header {
    width: 40%;
    h3 {
      font-size: 18px;
    }
  }
  .content {
    width: 60%;
    display: flex;
    flex-direction: column;
    span {
      color: #737188;
      font-size: 13px;
      align-self: center;
      margin-bottom: 25px;
      text-align: center;
    }
  }
}

.create-account-button {
  outline: none;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  background-color: #080534;
  border: none;
  margin-top: 20px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  &:hover,
  &:active {
    background-color: #080534d2;
    outline: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.social-medias-login {
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  button {
    flex-grow: 1;
    background: none;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.btn-login{
  font-size: 16px !important;
}

@media (max-width: 767px) {
  .Register {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .header {
      width: 100%;
      h3 {
        font-size: 18px;
      }
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      span {
        color: #737188;
        font-size: 13px;
        align-self: center;
        margin-bottom: 25px;
        text-align: center;
      }
    }
  }
}
