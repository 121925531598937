.form-fieldset {
  border: 1px dashed #ef6328;
  padding: 18px;
  margin-bottom: 20px;
  border-radius: 16px;

  legend {
    font-weight: 1em !important;
  }

  .required-label {
    color: red;
    font-size: 18px;
  }
}
