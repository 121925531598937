.event-card {
  background: #FFFFFF;
  border: 1px solid #ECECF0;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: none !important;
  margin-bottom: 20px;

  border-radius: 12px !important;
  text-decoration: none;
  color: #4D4A70;

  &:hover {
    text-decoration: none;
    box-shadow: 0 2px #ddd !important;
  }

  .card-img-top {
    height: 180px;
    border-radius: 12px 12px 0px 0px;
    margin: -33px;
    margin-bottom: 24px;
    width: calc(100% + 66px);
    object-fit: cover;
  }
  .card-body {
    height: 97px;
    padding: 0;
    margin: 0 -10px;
    .card-title {
      // font-family: Cerebri Sans;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #080534;
    }

    .card-text {
      // font-family: Cerebri Sans;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #4D4A70;
    }
  }
}

.events-container {
  margin: 20px 0;
}


@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    .event-card {
      img.card-img-top {
        width: 100% !important;
        margin: -10px 0 10px !important;
      }
      .card-body {
        margin: 10px;
      }
    }
    .event-card-wrapper {
      padding: 0;
    }
  }

  .org-logo {
    width: 24px !important;
    height: 24px !important;
    object-fit: cover;
    display: inline-block;
    margin-right: 10px;
  }