.extra-service {
  border: 1px solid #f4f4f7;
  border-radius: 25px;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  h4 {
    font-size: 18px;
    margin-top: 20px;
  }
  .coming-soon {
    font-size: 14px;
    margin-top: 9px;
  }
  .price {
    margin-top: 10px;
  }
  p {
    font-size: 15px;
  }
}
.sweet-alert{
  h2{
    font-size: 18px;
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .text-muted  {
    padding-bottom: 50px;
  }
}
.additional-service-icon {
  max-width: 60px;
}

@media (max-width: 767px) {
  .extra-service {
    h4 {
      font-size: 14px;
      margin-top: 9px;
    }
    .price {
      width: 100%;
      text-align: center;
    }
    .product-name {
      margin-top: 14px;
      font-size: 17px;
    }
  }

}
