.Header {
  display: flex;
  height: 80px;
  width: 100%;
  padding: 0px 10%;
  img {
    width: 300px;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    padding-left: 103px;
  }
  button {
    background: none;
    border: none;
    width: 103px;
    color: #ff6526;
    outline: none;
  }
}

@media (max-width: 479.98px) {
  .Header {
    height: 60px;
    padding: 0px 6%;
    img {
      width: 160px;
      justify-self: flex-start;
      margin-left: 0px;
      margin-right: auto;
      align-self: center;
      padding-left: 0px;
    }
    button {
    }
  }
}
