.landing-navigation {
  height: 84px;
  border-bottom: 1px solid rgba(205,205,205, 0.8) !important;
  // background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(180deg, #111111 0%, rgba(0, 0, 0, 0) 100%);

  .nav-link {
    color: white;
    &.text-orange {
      color: #FF5B22;
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5");
  }

  .navbar-toggler {
    border-color: none;
    color: #120F46;
  }
}

.sm-visible {
  display: none;
}

.alt-search-form {
  display: none;
}

@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    .landing-navigation {
      background: none !important;
      background-color: white !important;
      width: 100%;

      .nav-link {
        color: #120F46;
      }

      > .ml-auto, > .mx-auto {
        margin-left: 10px !important;
        width: 100%;
      }
    }

    .lg-visible {
      display: none;
    }
    .sm-visible {
      display: block;
      margin-top: 10px;
    }

    .landing-navigation .navbar-toggler {
      float: right;
      margin-top: 8px;
    }

    .show.navbar-collapse {
    }

    .navbar-collapse {
      // margin: 0 -16px;
      transition: none !important;
      background-color: white;
      transition: none !important;
      -webkit-transition: none !important;
      position: absolute;
      top: 84px;
      left: 0;

      &.collapsing {
        transition: none !important;
        -webkit-transition: none !important;
        overflow: visible !important;
        // position: absolute;
        animation: none !important;
      }

    }

    .text-white {
      color: #120F46 !important;
    }

    .alt-search-form {
      display: block;
    }

  }

  .nav-search {
    margin-right: 20px;
  }

  .nav-search-form {
    input {
      border: 1px solid #888;
      background: rgba(0,0,0,0.2);
      color: #fff;

      &:focus {
        background: rgba(0,0,0,0.2);
        color: #DD3434;
        outline: none;
        box-shadow: none;
        border-color: #AAA;
      }
    }
  }

  .nav-item-hidden {
    display: none;
  }

  .alt-search-form {
    input, input:focus {
      background: white !important;
      width: 96%;
      margin: 0 12px;
    }
  }