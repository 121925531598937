@import "../../assets/styles/variables";
html,
body {
  // overflow-x: hidden !important;
}
body {
  position: relative  !important;
}


.event-row {
  border-bottom: 1px solid $gray-100;
  td,
  th {
    padding: 1em;
  }
  &__date {
    h1 {
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: 1;
      font-size: 32px;
      position: relative;
    }
    small {
      display: block;
      text-align: center;
    }
  }
  &__date:before {
    content: "\A";
    border-right: 2px solid #dee2e6;
    position: absolute;
    top: 19%;
    right: 0;
    height: 62%;
  }
  &__description {
    h5 {
      line-height: 1.7;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
  }

  &__price-range {
    font-weight: 500;
    .flex-center {
      padding-top: 12px;
    }
  }
}

.event-row td,
.event-row th {
  position: relative;
}
.p5{
  padding: 5px !important;

}

@media (max-width: 767px) {

  .events-list button {
    display: none;
  }
  .event-row {
    &__price-range {
      width: 105px;
      .flex-center {
        padding-top: 3px;
      }
    }
  }
  //.event-row__date {
  //  border-right: 1px solid #dee2e6;
  //}
  .table-responsive > table {
    thead > tr {
      th {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }
  .flex-center {
    h1 {
      font-size: 25px;
    }
    small {
      font-size: 11px;
    }
  }
  .text-align-right {
    text-align: right !important;
  }
  .fecha-mt {
    margin-top: 2px;
  }
  .fecha-padding-top {
    padding-top: 5px;
  }
  .backgroundOrange {
    background-color: #ffebe5;
  }
}
.flex-center > small {
  text-transform: capitalize;
}
