.captcha-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-body {
    min-height: 300px;
    #g-recaptcha {
      div {
        margin: 0 auto;
      }
    }
    h3 {
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
    }
    p {
      color: #4d4a70;
      font-size: 13px;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .flex-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
      button {
        width: 130px;
        margin: 0px 15px 0px 15px;
      }
      .btn-secondary {
        background: #f7f7f9;
        border-color: #f7f7f9;
        color: #8887a2;
      }
    }
    .handypass-navigation,
    .footer {
      display: none;
    }
    iframe {
      height: 150px;
    }
  }
}


@media (max-width: 767px) {
  .modal-body{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}