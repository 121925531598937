.landing-header {
  position: relative;

  .carousel {
    height: 600px;

    .carousel-inner {
      height: 100%;
    }

    .carousel-item {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlayer {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "\A";
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.35);
      }

    }

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }
  }

  .carousel-caption {
    width: 40%;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    left: 120px;
    text-align: left;

    h3 {
      // font-family: Cerebri Sans;
      margin-top:86px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 41px;
      color: #FFFFFF;
    }

    p {
      // font-family: Cerebri Sans;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 19px;

      color: #FFFFFF;

      margin: 5px 0 22px;
      opacity: 0.8;
    }
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 138px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    margin-right: 10px;
    margin-left: 120px;
    list-style: none;

    li {
      margin-left: 0;
    }
  }

  .c2a {
    margin-top: 0;
    min-width: 170px;
    line-height: 28px;
    width: auto;
    display: inline-block;
    max-width: 200px;
  }
}


@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {

    .landing-header {
      position: relative;

      .carousel {
        height: 100vh;

        .carousel-inner {
          height: 100%;
        }
      }
    }

    .carousel-caption {
      width: 100% !important;
      text-align: center !important;
      left: 0 !important;

      h3 {
        // margin-top: 0 !important;
        text-align: center !important;
      }

      p {
        text-align: center !important;
        margin-top: 20px !important;
      }

      .c2a {
        margin: 80px auto !important;
      }
    }

    .carousel-indicators {
      bottom: 30px !important;
      justify-content: center !important;
      margin: 0 auto !important;
    }
  }
