.btn-light-gray {
  width: 43px;
  height: 43px;

  &:first-child {
    margin-right: 10px;;
  }
  background: #F6F6FA;
  border: none !important;
  border-radius: 6px;
  color: #4D4A70;

  &:hover {
    background-color: #F6F6FA;
    opacity: 0.7;
    color: #4D4A70;
  }

  &.disabled {
    background-color: white;
    color: #4D4A70;
  }
}

.events-pagination {
  float: right;
}

.section-header {
  margin-top: 80px;
  margin-bottom: 80px;

  h5 {
    // font-family: Cerebri Sans;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 44px;
    color: #080534;
  }

  p.subtitle {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    text-align: center;

    color: #4D4A70;
  }
}

hr.divider {
  border-color: #ECECF0;
  border-width: 1px;
  margin: 80px auto;
}

.landing-clients {
  .col-md-2 {
    display: table;
    // width: 100%;
    // height: 200px;
    // border: 3px dashed #1c87c9;
    text-align: center;
    height: 146px;

    a {
      display: table-cell;
      vertical-align: middle;
      max-width: 100%;
    }

    img {
      width: 90%;
    }
  }
}

.btn-contact {
  border: 1px solid #4D4A70;
  border-radius: 6px;
  // font-family: Cerebri Sans;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4D4A70;
}

@media
  only screen
  and (max-width: 760px), (min-device-width: 768px)
  and (max-device-width: 1024px)  {
    .section-header {
      margin: 30px 0;
    }

    .landing-clients .col-md-2 {
      padding-bottom: 20px;

      a img {
        max-width: 70%;
      }
    }
  }

.capitalized {
  text-transform: capitalize;
}