.orange {
  color: #ff6526;
  cursor: pointer;
}

.view-more-dates {
  padding-top: 16px;
}

.eventHeader > div > div > div > div > img {
  border-radius: 50px;
  margin: 0 auto;
  text-align: center;
}

.eventHeader h3.title {
  margin-top: 15px !important;
  margin-left: 10px !important;
  display: inline-block;
  line-height: 1;
  font-size: 22px;
  text-align: center; 
}

@media (min-width: 768px) {
  .eventHeader > div > div > img {
    float: left;
  }
}
@media (max-width: 767px) {
  .eventHeader {
    text-align: center;
  }
  .eventHeader > div > div > img {
    // border-radius: 50px;
    // margin: 0 auto;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;
    // margin-top: -55px;
    // width: 50px !important;
    // height: 50px !important;
    border-radius: 50px;
    margin: 0 auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -80px;
    min-width: 70px;
    min-height: 70px;
  }
  .event-cover {
    height: 250px !important;
    max-height: 150px !important;
  }

  .eventHeader h3.title {
    margin-top: 5px !important;
    margin-top: 8px !important;
  }

  div > div > p {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .alignCenter {
    text-align: center;
  }
  .view-more-dates {
    display: none;

  }
  .hr_top_ticket_types {
    margin-bottom: 0px !important;
  }
}
