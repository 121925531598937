@font-face {
  font-family: "CerebriSansRegular";
  src: url("/fonts/WEB/CerebriSans-Regular.woff") format("woff"),
    url("/fonts/OTF/CerebriSans-Regular.otf") format("opentype");
}

.navbar-light .navbar-nav .nav-link {
  &.blue-link {
    color: #24205a;
    font-weight: light;
  }

  &.orange-link {
    color: #ff6526;
  }
}

.container.w860 {
  max-width: 860px;
  margin: auto;
}

.organization-header {
  margin-top: 50px;

  &__icon {
    border-radius: 50%;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    display: inline-block;
  }

  &__title {
    display: inline-block;
    line-height: 1;
    font-size: 22px;
  }
}

.navbar.center .navbar-inner {
  text-align: center;
}

.navbar.center .navbar-inner .nav {
  display: inline-block;
  float: none;
}

.handypass-navigation {
  padding: 20px 0 0;
}

.text-bold {
  font-weight: 500;
}

.full-width {
  width: 90%;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  color: $handypass-blue;
  font-weight: 600;
  font-family: CerebriSansRegular;
}

.btn-lg {
  font-size: 15px;
  height: 48px;
}

small {
  font-size: 13px;
  font-weight: 200;
}

.events-list {
  & thead tr th {
    font-weight: 200;
    font-size: 13px;
  }
}

.vline {
  width: 4px;
  height: 80%;
  border: none;
  border-right: 1px solid $gray-100;
  float: right;
  display: block;
}

.social-sharing {
  small,
  svg {
    margin-right: 7px;
  }

  .fb-icon {
    color: #3d5e96;
  }

  .tw-icon {
    color: #46ade9;
  }
}

.select-width {
  width: 70px;
}

/*Events*/
.event {
  h3.title {
    font-size: 18px;
    margin-top: 22px;
    margin-left: 70px;
  }
  h3.subtitle {
    font-size: 18px;
  }
  h3.intro {
    margin-top: 54px;
    font-size: 20px;
  }
  .organization-logo {
    margin-left: 0px;
    margin-top: 9px;
  }
  p.orange {
    color: #ff6526;
    margin-top: 23px;
    margin-left: 13px;
    font-size: 12px;
    cursor: pointer;
  }
  p.small {
    color: gray;
    font-size: 13px;
  }
  p {
    color: #080534;
  }
}
.footer {
  margin-top: 3.4em;
  border-top: 1px solid $gray-100;
  h5 {
    font-size: 16px;
    margin: 1.4em 0;
    text-align: left;
  }
  .blue-link,
  .nav-link {
    text-align: left;
  }
  .nav-item a {
    color: $handypass-link-default;
    font-weight: 200;
    padding: 0.4em 0;
    &.active,
    &:hover {
      color: $handypass-blue;
      font-weight: 400;
    }
  }
  .footer-last-row {
    border-top: 1px solid $gray-100;
    margin: 1.5em 0;
    padding-top: 1.3em;
    font-size: 13px;
    font-weight: 300;
  }
  .cards {
    text-align: right;
  }
  .copyright {
    text-align: left;
  }
}
.card {
  background: white;
  padding: 33px;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  box-shadow: -1px 2px 41px 2px rgba(225, 225, 225, 0.5);
  border-radius: 10px;
  width: 100%;
  hr {
    margin-top: 10px;
  }
  h2 {
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
  }
  ul {
    margin-left: 41px;
    margin-top: 7px;
    li {
      padding-bottom: 14px;
      padding-left: 35px;
      span {
        margin-left: 26px;
      }
    }
  }
}

/*PurchaseSummary*/
h3.price {
  font-size: 18px;
  color: #ff5b22;
}

hr {
  border: none;
  border-top: 1px dotted #d7d7dd;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.summary-title {
  color: #737188;
}
.items {
  padding: 14px 0px 14px 0px;
  border: none;
  border-top: 1px dotted #d7d7dd;
}
.blue-link,
.nav-link {
  color: #24205a;
  font-weight: light;
  text-align: center;
}

//*Globals*//
.svg-center {
  display: block;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .text-xs-center {
    text-align: center;
  }

  /*Reduces padding in card for mobile devices*/
  .card {
    padding: 10px 0px 10px 0px;
    margin-top: 10px;
    width: 100%;
  }
}

.organization-header {
  margin-bottom: 1.4rem;
}

h3.organization-header__title {
  margin-top: 15px;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .organization-header img {
    float: left;
  }
}
@media (max-width: 767px) {

  .organization-header {
    &.row {
      margin-right: 0px;
    }
    .col-sm-12 > div > div {
      margin-left: 15px;
    }
  }

  .responsive-social-share.row {
    margin-right: 0px;
  }
  .organization-header img {
    border-radius: 50px;
    margin: 0 auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -80px;
    min-width: 70px;
    min-height: 70px;
  }
  .organization-header h3.organization-header__title {
    text-align: center;
    width: 96%;
    margin: 15px !important;
  }
  .organization-header .social-sharing {
    display: none;
  }
  .alert {
    margin-top: 1em;

  }
}
