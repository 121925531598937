.CompleteUserData {
  p:not(.text-error) {
    font-weight: 900;
    font-size: 16px;
    &:first-child {
      font-weight: 500;
      color: #ff6526;
      margin-bottom: 20px;
    }
    span {
      font-weight: 400;
    }
  }
  label {
    font-weight: 900;
    font-size: 16px;
  }
  .text-error {
    color: #d50000;
  }
}
