.list-group-item .svg-inline--fa {
  margin-right: 10px !important;
}
.marginIcons{
  margin-right: 10px !important;
  margin-left: 10px !important;
}


@media (max-width: 767px) {
  .marginIcons {
    margin-right: 0px !important;
    margin-left: 0px !important;  }
  .card_icons{
    margin: 3px;
  }
}