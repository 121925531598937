.ticket-card {
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0 1px 14px #ddd;
  margin-bottom: 20px;

  .ticket-content {
    margin: 20px 25px;
  }

  .ticket-label {
    font-size: 11px;
    font-weight: 500;
    color: #aba9bb;
    margin-bottom: 4px;
  }

  .ticket-row {
    margin: 0 0 10px 0;
  }

  .ticket-header {
    position: relative;
    height: 150px;

    // background-color: #FBAB7E;
    // background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

    background-color: #FF9A8B;
    background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);

    .ticket-header-content {
      padding: 50px 38px;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;

      h3 {
        margin-top: 14px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }


    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    // &:after {
    //   top: 0;
    //   left: 0;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   content: "\A";
    //   // background: rgb(0,0,0);
    //   // background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(2,0,36,0.4) 10%, rgba(0,212,255,0) 100%);
    // }
  }

  .ticket-qr {
    // margin: 40px 0 40px;
    margin-top: 10px;
    width: 130px;
    height: 130px;
  }

  .ticket-address {
    height: 50px;
  }

  .ticket-info {
    font-size: 12px;
    font-weight: 300;
    color: #2c2a4e;
  }

  .ticket-qr-wrapper {
    text-align: center;
    border-top: 1px dashed #ddd;
    position: relative;

    &:before, &:after {
      position: absolute;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #f2f2f2;
      content: "\A";
      top: -12px;
      box-shadow:  inset 0 0 14px #ddd;
    }

    &:before {
      left: -38px;
    }

    &:after {
      right: -38px;
    }
  }
}

.col-lg-6.event {
  padding: 22px;
}

@media (min-width: 1024px) {
  .fixed-content {
    min-height: 69px;
  }

}