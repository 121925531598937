.PaymentCards {
  margin-top: 15px;
  .MuiButton-root {
    text-transform: none;
    color: #ff5b22;
    text-decoration: underline;
    outline: none;
  }
  .MuiRadio-root {
    color: #ff5b22 !important;
  }
  label {
    margin: 0;
    font-size: 15px;
    line-height: 19px;
    color: #4d4a70;
    font-family: 'CerebriSans';
  }
}
