.terms-and-conditions {
  background-color: #e9ecef;
  border-radius: 0.4rem;
  height: 400px;
  overflow: scroll;
  border-style: solid;
  border-width: 2em;
  border-color: #e9ecef;
}
.title-20 {
  font-size: 20px;
  text-indent: 15px;
}
