ul {
    padding-left: 0px;
}

.card {

  &.no-padding {
    padding: 0;
    overflow: hidden;
    ul {
      margin: auto;
    }
  }

  .card-content {
    padding: 33px 33px 10px 33px;
  }
}

.mdText {
  ul, li {
    margin: 0;
    padding: 0;

  }
}