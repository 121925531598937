hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

hr.mt0 {
  margin-top: 0px;
}

hr.mt35 {
  margin-top: 35px;
}

hr.mb14 {
  margin-bottom: 14px;
}



.ticket-types {
  p {
    margin-top: 20px;
  }
  .orange {
    margin-left: 0px !important;
    
  }
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

select {
  width: 60px;
  height: 30px;
}

.counter {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  float: left;
}

.counter > span {
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-weight: 700;
}

.counter > .buttons {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.counter button,
.counter button:focus {
  background: #f5f5f5;
  border-radius: 5px;
  color: #1c313a;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 6px;
  margin: 0px;
}

.counter button:first-child {
  margin-bottom: 4px;
}

.counter button.small {
  padding: 0px 6px;
  margin: 0px;
  font-size: 14px;
}

.counter button.small:first-child {
  margin-bottom: 4px;
}

@media (min-width: 767px) {
  /*Align comprar botton to the right on desktop*/
  .btn-align {
    margin-right: 50%;
    margin-left: 50%;
    width: 160px;
  }
  hr.mb37 {
    margin-bottom: 37px;
  }
  p.orange{
    margin-top: 16px !important;
  }
}

@media (max-width: 767px) {
  .ticket-types {
    h4 {
      font-size: 1rem;
    }
  }
  .btn-lg {
    width: 100% !important;
  }
  .proximamente {
    font-size: 0.9rem;
  }
  .description {
    padding-left: 10px;

  }
  .courtesy {
    text-align: center;
    margin-bottom: 16px !important;
  }
  .add_courtesy {
    margin-bottom: 16px;

  }
  .mw-22{
    max-width: 22% !important;
  }
  
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-bottom: 18px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled{
  color: #000000;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;

}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #000000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #757575;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ff63268e;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ff63268e;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #ff6526;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff6526;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__month-view__days__day {
  color: #000000;
}